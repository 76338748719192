import { render, staticRenderFns } from "./Swaps.vue?vue&type=template&id=2192e91d&scoped=true&"
import script from "./Swaps.vue?vue&type=script&lang=ts&"
export * from "./Swaps.vue?vue&type=script&lang=ts&"
import style0 from "./Swaps.vue?vue&type=style&index=0&id=2192e91d&lang=sass&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2192e91d",
  null
  
)

export default component.exports
import QBanner from 'quasar/src/components/banner/QBanner.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QCard from 'quasar/src/components/card/QCard.js';
import QIcon from 'quasar/src/components/icon/QIcon.js';
import QTooltip from 'quasar/src/components/tooltip/QTooltip.js';
import QPopupEdit from 'quasar/src/components/popup-edit/QPopupEdit.js';
import QInput from 'quasar/src/components/input/QInput.js';
import QBtnToggle from 'quasar/src/components/btn-toggle/QBtnToggle.js';
import QExpansionItem from 'quasar/src/components/expansion-item/QExpansionItem.js';
import QCardSection from 'quasar/src/components/card/QCardSection.js';
import QItem from 'quasar/src/components/item/QItem.js';
import QBtnGroup from 'quasar/src/components/btn-group/QBtnGroup.js';
import qInstall from "../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(component, 'components', {QBanner,QBtn,QCard,QIcon,QTooltip,QPopupEdit,QInput,QBtnToggle,QExpansionItem,QCardSection,QItem,QBtnGroup});
